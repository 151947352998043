import clsx from 'clsx';
import {graphql, PageProps} from 'gatsby';
import React from 'react';
import LuArticleHero from '../components/lu-article-hero';
import DefaultLayout from '../layouts/default';
import heroImage from '../images/contact-hero.jpg';
import {Col, Row} from 'antd';
import {GAP_SIZE_2, GAP_SIZE_BASE} from '../constants';
import * as styles from './contact.module.scss';
import {useTranslation} from 'react-i18next';
export type ContactsViewModel = {
  company: string,
  address: string,
  tel: string[],
  fax: string,
}[];

const ContactPage = ({data, location}: PageProps) => {
  const {t} = useTranslation('contact');
  const PAGE_DATA = {
    title: 'Contact Us',
    heroImage: heroImage,
    subTitle: t('联系我们'),
  };
  const CONTACTS_DATA: ContactsViewModel = [
    {
      company: t('益联（香港）有限公司'),
      address: t('九龙青山道538号半岛大厦11/F楼05~06室'),
      tel: ['852-31880320'],
      fax: '852-31880312',
    },
    {
      company: t('长乐联丰染整有限公司'),
      address: t('福建省福州市滨海工业区（长乐松下镇首址）'),
      tel: ['86-591-28765999', '86-591-28765777'],
      fax: '86-591-28765366',
    },
    {
      company: t('福建恒益纺织有限公司'),
      address: t('福建省福州市滨海工业区（长乐古槐镇仙桥）'),
      tel: ['86-591-28709888', '86-591-28709777'],
      fax: '86-591-28709666',
    },
  ];
  return (
    <DefaultLayout location={location}>
      <LuArticleHero
        bgImageUrl={PAGE_DATA.heroImage}
        title={PAGE_DATA.title}
        subTitle={PAGE_DATA.subTitle}
      />
      <section className={styles.section}>
        {CONTACTS_DATA.map(contact => (
          <div key={contact.company} className={styles.contact}>
            <Row
              className={styles.content}
              gutter={GAP_SIZE_2}
              justify="center"
              align="stretch"
            >
              <Col sm={8} span={24} className={styles.company}>
                {contact.company}
              </Col>
              <Col sm={1} span={0}>
                <div className={styles.divider} />
              </Col>
              <Col sm={12} span={24}>
                <Row gutter={GAP_SIZE_BASE} wrap={false}>
                  <Col className={styles.label}>{t('地址:')}</Col>
                  <Col>{contact.address}</Col>
                </Row>
                <Row gutter={GAP_SIZE_BASE}>
                  <Col>TEL : </Col>
                  <Col>
                    {contact.tel.map(tel => (
                      <div key={tel}>{tel}</div>
                    ))}
                  </Col>
                </Row>
                <Row gutter={GAP_SIZE_BASE}>
                  <Col>FAX : </Col>
                  <Col>{contact.fax}</Col>
                </Row>
              </Col>
            </Row>
          </div>
        ))}
      </section>
    </DefaultLayout>
  );
};

export default ContactPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
