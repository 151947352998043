import {Col, Row} from 'antd';
import clsx from 'clsx';
import React from 'react';
import * as styles from './index.module.scss';
import {useI18next} from 'gatsby-plugin-react-i18next';

type PropTypes = {
  title: string | string[];
  subTitle: string | string[];
  bgImageUrl: string;
  showMask?: boolean;
};

/**
 * 顶部大标题
 */
export default function LuArticleHero({
  title,
  subTitle,
  bgImageUrl,
  showMask = false,
}: PropTypes) {
  const i18next = useI18next();
  const isEnglish = i18next.language === 'en';
  const titleLines = typeof title === 'string' ? [title] : title;
  return (
    <div className={styles.container}>
      <Row className={styles.hero} justify="center" align="middle">
        {showMask && <div className={styles.mask} />}
        <img className={styles.bg} src={bgImageUrl} alt="" />
        <Col className={styles.main}>
          <div className={styles.content}>
            {titleLines.map(line => (
              <div key={line} className={styles.title}>
                {line}
              </div>
            ))}
            {!isEnglish && <div className={clsx('boxed-text', styles.subTitle)}>
              {subTitle}
            </div>}
          </div>
        </Col>
      </Row>
    </div>
  );
}
